import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ThemeContext from "../context/ThemeContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneSquare,
  faSquareEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const ContactPage = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="Contact Techimpace Best Digital Agency for Website Designing, Web Development, Branding and ERP Development" />
          <section className={theme.dark ? " dark dark-5 " : " light "}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="row">
                    <div className="col-12">
                      <h2>Contact Us</h2>
                      <p className="lead">We appreciate your interest</p>
                      <hr></hr>
                    </div>
                    <div className="col-6">
                      <p>
                        <FontAwesomeIcon icon={faPhoneSquare} /> 779 766 1888
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faPhoneSquare} /> 956 451 0202
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faSquareEnvelope} />{" "}
                        office@techimpace.com
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faSquareEnvelope} />{" "}
                        sales@techimpace.com
                      </p>
                    </div>

                    <div className="col-6">
                      <p>
                        <b>Registered Office</b>
                      </p>
                      <h6>
                        Topobivab, RBC Road,
                        <br />
                        Lakurdi Karmakar Para, Purba Bardhaman,
                        <br />
                        West Bengal - 713102, India
                      </h6>
                    </div>
                  </div>
                  <a
                    href="https://wa.me/919564510202?text=I'm%20interested%20in%20your%20business%20please%20callback"
                    className={
                      theme.dark
                        ? " btn btn-light  btn-rounded "
                        : " btn btn-success btn-rounded "
                    }
                  >
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </a>
                  <Link
                    to="/"
                    className={
                      theme.dark
                        ? " btn btn-light btn-outline btn-rounded "
                        : " btn btn-dark btn-outline btn-rounded "
                    }
                  >
                    Chat with Us
                  </Link>
                </div>
                <div className="col-lg-4 m-t-40">
                  <div
                    className={
                      theme.dark
                        ? "call-to-action bg-branding text-dark"
                        : "call-to-action call-to-action-dark"
                    }
                  >
                    <h3 className={theme.dark ? "text-dark text-gray-900" : ""}>
                      Are Your Looking for a In-Person Meeting?
                    </h3>
                    <p>
                      Please book an appointment here. Its completely free and
                      worthy.
                    </p>
                    <a
                      className={
                        theme.dark
                          ? "btn btn-outline btn-dark"
                          : "btn btn-danger"
                      }
                      href="https://calendly.com/techimpace"
                    >
                      Click here to book appointment
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default ContactPage
